import Notification from "../components/informationCenter/Notification"


type Props = {}

export default function NotificationAdminScreen({ }: Props) {
    return (
        <>
            <Notification />
        </>
    )
}