import PersonalProfile from '../components/users/PersonalProfile'


type Props = {}

export default function ViewPersonalProfileScreen({ }: Props) {
    return (
        <>
            <PersonalProfile />
        </>
    )
}