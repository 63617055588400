import Profile from "../../components/profile/Profile"


type Props = {}

export default function ProfileScreen({ }: Props) {
  return (
    <>
      <Profile />
    </>

  )
}