import OnboardProfile from "../../components/setupProfile/OnboardProfile"


type Props = {}

export default function ProfileSetup({ }: Props) {

  return (
    <>
      <OnboardProfile />
    </>
  )
}