import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Auth from './screens/Auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from './screens/ForgotPassword';
import ChangePassword from './screens/ChangePassword';

import NotificationAdminScreen from './screens/NotificationAdminScreen';
import ViewPersonalProfileScreen from './screens/ViewPersonalProfileScreen';
import SeeAllTithesPaid from './screens/SeeAllTithesPaid';
import UserAnalysis from './screens/UserAnalysis';
import OrderAnalysis from './screens/OrderAnalysis';
import SeeAllOnlineTithesPaid from './screens/SeeAllOnlineTithesPaid';


import OfferingAnalysis from './screens/OfferingAnalysis';
import SeedsAnalysis from './screens/SeedsAnalysis';
import AnanimousGiveAnalysis from './screens/AnanimousGiveAnalysis';
import TitheRecordsScreen from './screens/TitheRecordsScreen';
// news
import SharedLayout from './screens/SharedLayout';
import DashboardAdmin from './screens/adminPages/DashboardAdmin';
import PrivateRoute from './components/routes/PrivateRoutes';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux'


import { useGetUserQuery } from './redux/features/api/apiSlice';
import { userLoggedIn } from './redux/features/auth/authSlice';
import OfferingPage from './screens/adminPages/OfferingPage';
import AnonymousPage from './screens/adminPages/AnonymousPage';
import SacrificePage from './screens/adminPages/SacrificePage';
import OnlineTithePage from './screens/adminPages/OnlineTithePage';
import OfflineTithePage from './screens/adminPages/OfflineTithePage';
import UserPage from './screens/adminPages/UserPage';
import ProfileSetup from './screens/media/ProfileSetup';
import ProfileScreen from './screens/media/ProfileScreen';
import BranchesMonthlyTransactionsPage from './screens/adminPages/BranchesMonthlyTransactionsPage';
import WelcomeScreen from './screens/WelcomeScreen';
import IsNotLoginAuth from './components/routes/IsNotLoginAuth';
import Loader from './components/loader/Loader';


function App() {
  const dispatch = useDispatch();
  const { data: user, isLoading } = useGetUserQuery();

  useEffect(() => {
    if (user) {
      dispatch(userLoggedIn({ user, accessToken: localStorage.getItem('accessToken') || '' }));
      // Reload the page
    }
  }, [user, dispatch]);

  if (isLoading) {
    return <div>
      <Loader />
    </div>;
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* Catch-all route to redirect to the appropriate dashboard or login page */}
        <Route path="*" element={<Navigate to={user ? `/account` : '/'} replace />} />
        <Route element={<IsNotLoginAuth />}>
          <Route path="/" element={<WelcomeScreen />} />
        </Route>
        <Route element={<IsNotLoginAuth />}>
          <Route path="/sign-in" element={<Auth />} />
        </Route>

        <Route element={<IsNotLoginAuth />}>
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Route>
        <Route element={<IsNotLoginAuth />}>
          <Route path="/reset-password" element={<ChangePassword />} />
        </Route>

        <Route element={<PrivateRoute allowedRoles={['account', 'admin']} />}>
          <Route path="/account" element={<SharedLayout />}>
            <Route index element={<DashboardAdmin />} />
            <Route path="offering" element={<OfferingPage />} />
            <Route path="anonymous" element={<AnonymousPage />} />
            <Route path="sacrifice" element={<SacrificePage />} />
            <Route path="live-tithes" element={<OnlineTithePage />} />
            <Route path="online-tithes" element={<OfflineTithePage />} />
            <Route path="all-users" element={<UserPage />} />
            <Route path="view-profile/:id" element={<ViewPersonalProfileScreen />} />
            <Route path='all-tithe-paid-online/:id' element={<SeeAllOnlineTithesPaid />} />
            <Route path='all-tithe-paid/:id' element={<SeeAllTithesPaid />} />
            <Route path="settings" element={<ProfileScreen />} />
            <Route path="profile-setup" element={<ProfileSetup />} />
            <Route path='manage-tithe-records' element={<TitheRecordsScreen />} />
            <Route path="notification" element={<NotificationAdminScreen />} />
            <Route path="branches-analysis" element={<BranchesMonthlyTransactionsPage />} />
            <Route path='offering-analysis' element={<OfferingAnalysis />} />
            <Route path="user-analysis-chart" element={<UserAnalysis />} />
            <Route path="order-analysis-chart" element={<OrderAnalysis />} />
            <Route path='anonymous-giving-analysis' element={<AnanimousGiveAnalysis />} />
            <Route path='seeds-analysis' element={<SeedsAnalysis />} />
          </Route>
        </Route>

      </Routes>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
}

export default App;
